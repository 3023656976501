<template>
  <div>
    <search @submit="submitSearch" />

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="actions" slot-scope="text, record">
        <a @click="handleApproval(record.id)" v-if="record.status_slug === 'checking'">审核</a>
        <a @click="handleShowDetail(record.id)" v-else>查看详情</a>
      </span>
    </a-table>

    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
  </div>
</template>

<script>
import Search from '@/pages/task/user_upload_records/SearchUserUploadRecord.vue'
import Pagination from "@/components/Pagination"
import formatTime from '@/utils/formatTime'
import { UserUploadRecordModel } from '@/models/user_upload_record.js'
const userUploadRecordModel = new UserUploadRecordModel()

export default {
  name: 'UserUploadRecordList',
  components: {
    Search,
    Pagination
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      editingId: 0,
      total: 0,
      current: 1,
      documentUrl: ''
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '用户账号',
          dataIndex: 'user_name'
        },
        {
          title: '昵称',
          dataIndex: 'nick_name'
        },
        {
          title: '上传类型',
          dataIndex: 'record_type'
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '上传日期',
          dataIndex: 'created_at',
          customRender: formatTime
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        },
      ]
    }
  },
  methods: {
    submitSearch(search) {
      this.current = 1
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    handleChange(page) {
      this.current = page
      this.fetchData()
    },

    handleApproval(id) {
      this.$router.push({
        path: `/task/user_upload_records/${id}`
      })
    },

    handleShowDetail(id) {
      this.$router.push({
        path: `/task/user_upload_records/${id}/show`
      })
    },
    
    fetchData() {
      this.loading = true
      const data = {
        ...this.query,
        page: this.current
      }
      userUploadRecordModel.index(data).then(res => {
        if (res.code === 0) {
          this.data = res.result
          this.total = res.total
        }
        this.loading = false
      })
    }
  }
}
</script>
