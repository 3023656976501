<template>
  <div>
    <a-form
      class="sfm-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="12">
        <a-col :span="3">
          <a-form-item>
            <a-input
              v-decorator="['username_or_nickname']"
              placeholder="请输入用户账号或昵称"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="3">
          <a-form-item>
            <a-select
              v-decorator="['status']"
              placeholder="状态"
            >
              <a-select-option value="checking"> 待审核 </a-select-option>
              <a-select-option value="passed"> 审核通过 </a-select-option>
              <a-select-option value="not_pass"> 审核未通过 </a-select-option>
              <a-select-option value="canceled"> 已取消 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-button type="primary" html-type="submit" class="search-button">
              搜索
            </a-button>
            <a-button @click="handleReset" class="search-button">
              刷新
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>

export default {
  name: 'UserUploadRecordSearch',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'document_search' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.search-button {
  margin-right: 10px;
}
</style>
